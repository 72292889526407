export default {
  async selectNota (Vue, idnota) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.nota)
      .innerJoin(
        tables.tnota,
        tables.nota.idtnota.eq(tables.tnota.idtnota)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.nota.idnota.eq(idnota),
          tables.nota.estado.gt(0),
        )
      )
      .exec())[0]
  },
}
